/* tslint:disable */
/* eslint-disable */
/**
 * Marco Email Parser API
 * Marco Email Parser API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const LoyaltyProgramEnum = {
    SkyMiles: 'SkyMiles',
    MileagePlus: 'MileagePlus',
    AAdvantage: 'AAdvantage',
    MileagePlan: 'MileagePlan',
    MarriottBonvoy: 'Marriott Bonvoy',
    HiltonHonors: 'Hilton Honors',
    IhgOneRewards: 'IHG One Rewards',
    WorldOfHyatt: 'World of Hyatt',
    TrueBlue: 'TrueBlue',
    RapidRewards: 'Rapid Rewards',
    AmericanExpressGoldCard: 'American Express Gold Card',
    ThePlatinumCard: 'The Platinum Card',
    ChaseFreedomUnlimited: 'Chase Freedom Unlimited',
    ChaseSapphirePreferred: 'Chase Sapphire Preferred',
    ChaseSapphireReserve: 'Chase Sapphire Reserve',
    BiltRewards: 'Bilt Rewards'
} as const;
export type LoyaltyProgramEnum = typeof LoyaltyProgramEnum[keyof typeof LoyaltyProgramEnum];


export function instanceOfLoyaltyProgramEnum(value: any): boolean {
    for (const key in LoyaltyProgramEnum) {
        if (Object.prototype.hasOwnProperty.call(LoyaltyProgramEnum, key)) {
            if ((LoyaltyProgramEnum as Record<string, LoyaltyProgramEnum>)[key] === value) {
                return true;
            }
        }
    }
    return false;
}

export function LoyaltyProgramEnumFromJSON(json: any): LoyaltyProgramEnum {
    return LoyaltyProgramEnumFromJSONTyped(json, false);
}

export function LoyaltyProgramEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): LoyaltyProgramEnum {
    return json as LoyaltyProgramEnum;
}

export function LoyaltyProgramEnumToJSON(value?: LoyaltyProgramEnum | null): any {
    return value as any;
}
