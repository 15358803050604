import {
  CurrencyAmount,
  DateRange,
  SearchSessionV2,
} from "@/generated/search.openapi";
import {
  LoyaltyAccount,
  LoyaltyProgramEnum,
} from "@/generated/email_parser.openapi";
import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";
import {
  addMonths,
  eachDayOfInterval,
  endOfMonth,
  format,
  isSaturday,
  isSunday,
  startOfMonth,
} from "date-fns";
import { HotelData } from "@/app/[bookingType]/search/context/searchProvider";
import { Calendar, Search } from "lucide-react";
import MapUnfold from "@/components/ui/svgComponents/MapUnfold";
import PointsIcon from "@/components/ui/svgComponents/PointsIcon";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function getSelectedLoyaltyAccount(
  loyaltyAccounts: LoyaltyAccount[],
  loyaltyProgram: string
) {
  return loyaltyAccounts
    .filter((account) => account.loyaltyProgram === loyaltyProgram)
    .sort((a, b) => {
      const pointsA = a.loyaltyPoints ?? -1; // Use nullish coalescing operator
      const pointsB = b.loyaltyPoints ?? -1; // Use nullish coalescing operator
      return pointsB - pointsA;
    })[0];
}

export function objectToUrlParams(obj: Record<string, string>) {
  return Object.entries(obj)
    .map(([key, value]) => `${key}=${value}`)
    .join("&");
}

export function diffTimeInHourAndMin(date1: Date, date2: Date) {
  const diffInMilliSeconds = Math.abs(date1.getTime() - date2.getTime());
  return `${Math.floor(diffInMilliSeconds / 3600000)} hr ${
    Math.floor(diffInMilliSeconds / 60000) % 60
  } min`;
}

export function calculateNumberOfNights(date1: Date, date2: Date) {
  // Create new dates without time information
  const startDate = new Date(
    Date.UTC(date1.getFullYear(), date1.getMonth(), date1.getDate())
  );
  const endDate = new Date(
    Date.UTC(date2.getFullYear(), date2.getMonth(), date2.getDate())
  );

  const diffInMilliSeconds = Math.abs(startDate.getTime() - endDate.getTime());
  return Math.ceil(diffInMilliSeconds / 86400000);
}

export function camelToSnakeCase(camelStr: string) {
  return camelStr.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);
}

export function snakeToCamelCase(snakeStr: string) {
  return snakeStr.replace(/_([a-z])/g, (g) => g[1].toUpperCase());
}

export const convertLocalDateToUTC = (localDate: Date) => {
  const dateInUTC = new Date(`${format(localDate, "yyyy-MM-dd")}T00:00:00Z`);
  return dateInUTC;
};

export const convertUTCToLocalDateIgnoringTimezone = (
  _utcDate: Date | string
) => {
  const utcDate = getDateFormat(_utcDate);
  return new Date(
    utcDate.getUTCFullYear(),
    utcDate.getUTCMonth(),
    utcDate.getUTCDate(),
    utcDate.getUTCHours(),
    utcDate.getUTCMinutes(),
    utcDate.getUTCSeconds(),
    utcDate.getUTCMilliseconds()
  );
};

export const getDateFormat = (date?: string | Date) => {
  return typeof date === "string" ? new Date(date) : (date as Date);
};

export const updateHtmlListStyles = (
  instructions: string | string[]
): string | string[] => {
  if (typeof instructions === "string") {
    return [
      instructions
        .replace(/<ul>/g, '<ul class="list-disc list-outside pl-4">')
        .replace(/<ol>/g, '<ol class="list-decimal list-outside pl-4">'),
    ];
  }
  return instructions.map((instruction) => {
    return instruction
      .replace(/<ul>/g, '<ul class="list-disc list-outside pl-4">')
      .replace(/<ol>/g, '<ol class="list-decimal list-outside pl-4">');
  });
};

export const camelCaseToHumanReadable = (camelCaseString: string) => {
  return camelCaseString
    .replace(/([A-Z])/g, " $1")
    .trim()
    .replace(/^./, (str) => str.toUpperCase());
};

export const snakeCaseToHumanReadable = (snakeCaseString: string) => {
  return snakeCaseString
    .replace(/_/g, " ")
    .replace(/^./, (str) => str.toUpperCase());
};

export const convertJsonToUrlParams = (
  queryParams: Record<string, string | number | boolean>
) => {
  return (
    "?" +
    encodeURI(
      Object.entries(queryParams)
        .map(([key, value]) => `${key}=${value}`)
        .join("&")
    )
  );
};

export const formatCurrency = (
  amount: string | number,
  currency: string = "USD",
  maximumFractionDigits: number = 2
) => {
  let _amount;
  if (typeof amount === "string") {
    _amount = Number(amount);
  } else {
    _amount = amount;
  }

  // Validate the currency code
  const validCurrency = isValidCurrency(currency) ? currency : "USD";

  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: validCurrency,
    maximumFractionDigits: maximumFractionDigits,
  }).format(_amount);
};

export const convertRawCurrencyToString = (
  currenctAmt: CurrencyAmount,
  isLocalized: boolean = false,
  maximumFractionDigits: number = 2
) => {
  if (currenctAmt?.currency === undefined || currenctAmt?.currency === null) {
    return currenctAmt?.value ? `${currenctAmt?.value}` : "";
  }
  if (currenctAmt?.currency === "points") {
    return `${Intl.NumberFormat("en-US").format(currenctAmt.value)}`;
  }
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: isLocalized
      ? currenctAmt.localizedAmount?.currency
      : currenctAmt.currency,
    maximumFractionDigits: maximumFractionDigits,
  }).format(
    isLocalized
      ? (currenctAmt.localizedAmount?.value as number)
      : currenctAmt.value
  );
};

// Helper function to check if the currency code is valid
const isValidCurrency = (currency: string) => {
  try {
    // Attempt to format a dummy number to check if the currency is recognized
    new Intl.NumberFormat("en-US", { style: "currency", currency }).format(0);
    return true;
  } catch {
    return false;
  }
};

export const imgCategoriesMapping: {
  [key: string]: string;
} = {
  pool: "/assets/image-category/pool.svg",
  lobby: "/assets/image-category/lobby.svg",
  spa: "/assets/image-category/spa.svg",
  exterior: "/assets/image-category/exterior.svg",
  room: "/assets/image-category/room.svg",
  "fitness center": "/assets/image-category/fitness-center.svg",
  views: "/assets/image-category/views.svg",
  "food & drink": "/assets/image-category/food-drink.svg",
  casino: "/assets/image-category/casino.svg",
};

export const replaceUrl = (newUrl: string) => {
  window.history.replaceState(
    { ...window.history.state, as: newUrl, url: newUrl },
    "",
    newUrl
  );
};

export const capitalizeFirstLetter = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const capitalizeEachWord = (str: string) => {
  return str
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

export const getTimeDataFromSearchResponse = (
  searchResponse: SearchSessionV2 | undefined
) => {
  const checkin =
    searchResponse?.request?.hotels?.searchDates?.checkin &&
    convertUTCToLocalDateIgnoringTimezone(
      searchResponse?.request?.hotels?.searchDates?.checkin
    );
  const checkout =
    searchResponse?.request?.hotels?.searchDates?.checkout &&
    convertUTCToLocalDateIgnoringTimezone(
      searchResponse?.request?.hotels?.searchDates?.checkout
    );

  const nightCount =
    checkout && checkin ? calculateNumberOfNights(checkin, checkout) : 0;

  return {
    checkin,
    checkout,
    nightCount,
  };
};

export const screenInnerWidth =
  typeof window !== "undefined" ? window.innerWidth : 0;

export const screenInnerHeight =
  typeof window !== "undefined" ? window.innerHeight : 0;

export const loyaltyAccountsColorMapping: {
  [key in LoyaltyProgramEnum]: string;
} = {
  [LoyaltyProgramEnum.AAdvantage]: "",
  [LoyaltyProgramEnum.HiltonHonors]: "#256885",
  [LoyaltyProgramEnum.IhgOneRewards]: "#964721",
  [LoyaltyProgramEnum.MarriottBonvoy]: "#871035",
  [LoyaltyProgramEnum.MileagePlan]: "",
  [LoyaltyProgramEnum.MileagePlus]: "",
  [LoyaltyProgramEnum.SkyMiles]: "",
  [LoyaltyProgramEnum.WorldOfHyatt]: "#A16F01",
  [LoyaltyProgramEnum.TrueBlue]: "",
  [LoyaltyProgramEnum.RapidRewards]: "",
  [LoyaltyProgramEnum.AmericanExpressGoldCard]: "",
  [LoyaltyProgramEnum.ThePlatinumCard]: "",
  [LoyaltyProgramEnum.ChaseFreedomUnlimited]: "",
  [LoyaltyProgramEnum.ChaseSapphirePreferred]: "",
  [LoyaltyProgramEnum.ChaseSapphireReserve]: "",
  [LoyaltyProgramEnum.BiltRewards]: "",
};

export const sortHotelByPointPercentile = (a: HotelData, b: HotelData) => {
  const aPercentile = a.pointsPercentile;
  const bPercentile = b.pointsPercentile;
  if (aPercentile === undefined || aPercentile === 0) {
    return bPercentile === undefined || bPercentile === 0 ? 0 : 1;
  }
  return aPercentile && bPercentile ? bPercentile - aPercentile : -1;
};

export const sortHotelByCashPercentile = (a: HotelData, b: HotelData) => {
  const aPercentile = a.cashPercentile;
  const bPercentile = b.cashPercentile;
  if (aPercentile === undefined || aPercentile === 0) {
    return bPercentile === undefined || bPercentile === 0 ? 0 : 1;
  }
  return bPercentile && aPercentile ? aPercentile - bPercentile : -1;
};

export const lgBreakPoint = 1100;
export const mdBreakPoint = 768;

export const checkWebview = (): boolean => {
  const userAgent = navigator.userAgent.toLowerCase();

  // Define patterns for webview detection
  const webviewPatterns = [
    /wv/,
    /webview/,
    /ip(hone|od|ad).*applewebkit(?!.*safari)/,
    /android.*(version\/[0-9]\.[0-9]).*chrome\/[0-9]{2}\.[0-9]{3}\.[0-9]{2}/,
    /; wv\)/,
  ];

  // Check userAgent against each pattern
  const isWebview = webviewPatterns.some((pattern) => pattern.test(userAgent));

  // Check if fbclid is present in the URL query parameters
  const urlParams = new URLSearchParams(window.location.search);
  const hasFbclid = urlParams.has("fbclid");

  return isWebview || hasFbclid;
};

export const getRating = (rating: number) => {
  return (rating * 2).toFixed(1);
};

export const getWeekendDates3MonthsFromNow = (): DateRange[] => {
  const today = convertUTCToLocalDateIgnoringTimezone(new Date());
  const thirdMonthStart = startOfMonth(addMonths(today, 3));
  const thirdMonthEnd = endOfMonth(thirdMonthStart);

  const allDates = eachDayOfInterval({
    start: thirdMonthStart,
    end: thirdMonthEnd,
  });

  const weekends: DateRange[] = [];

  for (let i = 0; i < allDates.length; i++) {
    const date = allDates[i];
    if (isSaturday(date)) {
      const sunday = allDates[i + 1];
      if (sunday && isSunday(sunday)) {
        weekends.push({ startDate: date, endDate: sunday });
        i++;
      }
    }
  }

  return weekends;
};

export const defaultInitialPageRoute = "/hotel/search";

export const navItems = [
  {
    key: "hotel-search",
    title: "Search",
    href: "/hotel/search",
    icon: <Search className="mr-2 h-4 w-4" />,
  },
  {
    key: "points",
    title: "Points",
    href: "/loyalty-dashboard",
    icon: <PointsIcon className="mr-2 h-4 w-4" />,
  },
  {
    key: "trips",
    title: "Trips",
    href: "/my-trips",
    icon: <Calendar className="mr-2 h-4 w-4" />,
  },
  {
    key: "map",
    title: "Map",
    href: "/travel-history",
    icon: <MapUnfold className="mr-2 h-4 w-4" />,
  },
];

type SocialMediaIcon = {
  link: string;
  image: string;
  imageTransparent: string;
  alt: string;
  name: string;
};

export const isValidEmail = (email: string) => {
  return new RegExp("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$").test(
    email
  );
};
export const socialMediaIcons: SocialMediaIcon[] = [
  {
    name: "instagram",
    link: "https://www.instagram.com/gondolaai",
    image: "/assets/icons/instagram.svg",
    imageTransparent: "/assets/icons/instagram-transparent.svg",
    alt: "instagram",
  },
  {
    name: "linkedin",
    link: "https://www.linkedin.com/company/gondolaai",
    image: "/assets/icons/linkedin.svg",
    imageTransparent: "/assets/icons/linkedin-transparent.svg",
    alt: "linkedin",
  },
  {
    name: "twitter",
    link: "https://twitter.com/gondola_ai",
    image: "/assets/icons/twitter.svg",
    imageTransparent: "/assets/icons/twitter-transparent.svg",
    alt: "twitter",
  },
];
